/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Component Imports */

import { Button } from "@mui/material";

/* Icon Imports */

import { Key, Logout } from "@mui/icons-material";

/* Authentication Imports */

import { signIn, signOut } from "next-auth/react";

const SignInButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#FFFFFF",
    color: "#000000",
    "&:hover": {
        backgroundColor: "#000000",
        color: "#FFFFFF",
    },
}));

const SignOutButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#FFFFFF",
    color: "#000000",
    "&:hover": {
        backgroundColor: "#000000",
        color: "#FFFFFF",
    },
}));

const AuthButton = ({ variant }: { variant: string }) => {
    if (variant === "sign_in") {
        return <SignInButton variant="contained" size="large" startIcon={<Key />} onClick={() => { signIn(); }}>Sign in</SignInButton>;
    }
    else {
        return <SignOutButton variant="contained" size="large" startIcon={<Logout />} onClick={() => { signOut(); }}>Sign Out</SignOutButton>;
    }
};

export default AuthButton;